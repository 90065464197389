import Vue from 'vue'

export default {
  namespaced: true,
  state: () => ({
    error: null,
    loading: false,

    data: [],
  }),
  getters: {
    error (state) {
      return state.error
    },
    loading (state) {
      return state.loading
    },

    data (state) {
      return state.data || []
    },

    formatter: () => (itemNumber) => ({
      ...itemNumber,
    }),
  },
  actions: {
    load ({commit}, options = {}) {
      const { before, ...payload } = {
        size: 50,
        page: 1,
        search: '',
        ...options,
      }
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        commit('setError', null)
        Vue.http.post('item_numbers/load', payload, before)
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          resolve(data)
        })
        .catch(e => {
          commit('setError', e)
          resolve({
            data: [],
            total: 0,
          })
        })
        .finally(() => commit('setLoading', false))
      })
    },
    submit ({commit, getters}, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        commit('setError', null)
        Vue.http.post('item_numbers/submit', payload)
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          const itemNumber = getters.formatter(data)
          commit('updateData', itemNumber)
          resolve(itemNumber)
        })
        .catch(e => { commit('setError', e), reject(e) })
        .finally(() => commit('setLoading', false))
      })
    },
    delete ({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        commit('setError', null)
        Vue.http.post('item_numbers/delete', payload)
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          commit('deleteData', data)
          resolve(data)
        })
        .catch(e => { commit('setError', e), reject(e) })
        .finally(() => commit('setLoading', false))
      })
    },
  },
  mutations: {
    setError (state, payload) {
      state.error = payload
    },
    setLoading (state, payload) {
      state.loading = payload
    },

    setData (state, payload) {
      state.data = payload
    },
    updateData (state, target) {
      const rowIndex = state.data.findIndex(row => row.id == target.id)
      if (rowIndex > -1) {
        state.data.splice(rowIndex, 1, target)
      } else {
        state.data.push(target)
      }
    },
    deleteData (state, target) {
      const rowIndex = state.data.findIndex(row => row.id == target.id)
      if (rowIndex > -1) {
        state.data.splice(rowIndex, 1)
      }
    },
  },
}