import lines from './lines'
import winders from './winders'
import config from './config'
import item_numbers from './item_numbers'
import merge_numbers from './merge_numbers'
import customers from './customers'
import carriers from './carriers'

export default {
  lines,
  winders,
  config,
  item_numbers,
  merge_numbers,
  customers,
  carriers,
}
