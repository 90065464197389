import store from '../store'

const routes = [
  {
    path: "/",
    component: () => import("@/layout/dashboard/DashboardLayout"),
    redirect: "/dashboard",
    meta: {
      requiresAuth: true,
      requiresNonOperator: true,
      requiresLine: true,
    },
    children: [
      {
        path: "/choose-line",
        name: "line",
        meta: {
          chooseLinePage: true,
        },
        component: () => import("@/pages/ChooseLine"),
      },
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/pages/Dashboard"),
      },
      {
        path: "/yarn-labels",
        name: "yarn label",
        component: () => import("@/layout/default"),
        children: [
          {
            path: "",
            component: () => import("@/pages/YarnLabel/Index")
          },
        ]
      },
      {
        path: "/box-labels",
        name: "box label",
        component: () => import("@/layout/default"),
        children: [
          {
            path: "",
            component: () => import("@/pages/BoxLabel/Index")
          },
        ]
      },
      {
        path: "/pallet-labels",
        name: "pallet label",
        component: () => import("@/layout/default"),
        children: [
          {
            path: "",
            component: () => import("@/pages/PalletLabel/Index")
          },
        ]
      },
      {
        path: "/packing-slips",
        name: "packing slip",
        component: () => import("@/layout/default"),
        children: [
          {
            path: "",
            component: () => import("@/pages/PackingSlip/Index")
          },
        ]
      },
      {
        path: "/manage",
        name: "manage",
        component: () => import("@/layout/default"),
        meta: {
          requiresAdmin: true
        },
        children: [
          {
            path: "item-numbers",
            component: () => import("@/pages/Manage/ItemNumbers")
          },
          {
            path: "tare-weight",
            component: () => import("@/pages/Manage/TareWeight")
          },
          {
            path: "carriers",
            component: () => import("@/pages/Manage/Carriers")
          },
          {
            path: "users",
            component: () => import("@/pages/Manage/Users")
          },
          {
            path: "customers",
            component: () => import("@/pages/Manage/Customers")
          },
        ],
      },
    ]
  },
  {
    path: '/',
    name: 'auth',
    component: () => import("@/layout/auth/AuthLayout"),
    redirect: 'login',
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import("@/pages/Auth/Login"),
      },
      {
        path: 'login_sso',
        name: 'login_sso',
        component: () => import("@/pages/Auth/LoginSSO"),
      },
    ]
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import("@/pages/Auth/Logout"),
  },
  { path: "*", component: () => import("@/pages/NotFound") }
];

export default routes;
