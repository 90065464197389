import Vue from 'vue'

export default {
  namespaced: true,
  state: () => ({
    error: null,
    loading: false,

    data: {},
  }),
  getters: {
    error (state) {
      return state.error
    },
    loading (state) {
      return state.loading
    },

    data (state) {
      return state.data
    },

    tare_weight (state) {
      const tare_weight = state.data.tare_weight || {}
      return {
        tube_core: parseFloat(tare_weight.tube_core) || 0,
        box: parseFloat(tare_weight.box) || 0,
        separator: parseFloat(tare_weight.separator) || 0,
        pallet: parseFloat(tare_weight.pallet) || 0,
      }
    },
  },
  actions: {
    get ({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        Vue.http.post('status/get', payload)
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          commit('setData', data)
          resolve(data)
        })
        .catch(e => { commit('setError', e), reject(e) })
        .finally(() => commit('setLoading', false))
      })
    },
    submit ({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        commit('setError', null)
        Vue.http.post('status/submit', payload)
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          commit('setData', data)
          resolve(data)
        })
        .catch(e => { commit('setError', e), reject(e) })
        .finally(() => commit('setLoading', false))
      })
    },
  },
  mutations: {
    setError (state, payload) {
      state.error = payload
    },
    setLoading (state, payload) {
      state.loading = payload
    },

    setData (state, payload) {
      state.data = {
        ...state.data,
        ...payload,
      }
    },
  },
}