import Vue from 'vue'

export default {
  namespaced: true,
  state: () => ({
    error: null,
    loading: false,

    data: [
      {
        id: '01',
        name: 'VB Line 1',
        color: '#ffc0cb',
      },
      {
        id: '02',
        name: 'VB Line 2',
        color: '#ffff00',
      },
    ],
  }),
  getters: {
    error (state) {
      return state.error
    },
    loading (state) {
      return state.loading
    },

    data (state) {
      return state.data || []
    },
    getByID: (state) => (line_id) => {
      return state.data.find(line => line.id == line_id)
    },
  },
  mutations: {
    setError (state, payload) {
      state.error = payload
    },
    setLoading (state, payload) {
      state.loading = payload
    },

    setData (state, payload) {
      state.data = payload
    },
  },
}