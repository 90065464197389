import Vue from 'vue'

export default {
  namespaced: true,
  state: () => ({
    error: null,
    loading: false,

    data: [
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
    ],
  }),
  getters: {
    error (state) {
      return state.error
    },
    loading (state) {
      return state.loading
    },

    data (state) {
      return state.data || []
    },

    line_position: (state) => (winder) => {
      let line_position = ''
      switch(winder) {
        case '01': case '02':
          line_position = '01'
          break
        case '03': case '04':
          line_position = '02'
          break
        case '05': case '06':
          line_position = '03'
          break
        case '07': case '08':
          line_position = '04'
          break
      }
      return line_position
    },
  },
  mutations: {
    setError (state, payload) {
      state.error = payload
    },
    setLoading (state, payload) {
      state.loading = payload
    },

    setData (state, payload) {
      state.data = payload
    },
  },
}