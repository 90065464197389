import Vue from 'vue'
import Vuex from 'vuex'
import { Notification } from "vue-notifyjs"

Vue.use(Vuex)

import modules from './modules'

const initialState = () => ({
  error: null,
  loading: false,
  appTitle: 'Label Printing - UA',
  appVersion: process.env.VUE_APP_VERSION || '1.0.0',
  apiHost: process.env.NODE_ENV == 'production' ? 'https://nuflex-api.ecofibre.com' : process.env.VUE_APP_API_HOST,
  currentSessionLine: sessionStorage.getItem('line') || 0,
})

export default new Vuex.Store({
  state: initialState,
  mutations: {
    setError (state, payload) {
      state.error = payload
    },
    setLoading (state, payload) {
      state.loading = payload
    },
    setSessionLine (state, line) {
      state.currentSessionLine = line
    },
  },
  actions: {
    notify({}, payload) {
      Notification.notify(payload)
    },
    setSessionLine({commit}, payload) {
      sessionStorage.setItem('line', payload)
      commit('setSessionLine', payload)
    },
  },
  getters: {
    error: state => state.error,
    loading: state => state.loading,
    appTitle: state => state.appTitle,
    apiHost: state => state.apiHost,
    line: state => state.currentSessionLine,
  },
  modules,
})
