import modules from './modules'

export default {
  namespaced: true,
  state: () => ({
    error: null,
    loading: false,
  }),
  getters: {
    error (state) {
      return state.error
    },
    loading (state) {
      return state.loading
    },
  },
  actions: {
    load ({commit, dispatch}) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        commit('setError', null)
        Promise.all([
          dispatch('config/get'),
          dispatch('item_numbers/load'),
          dispatch('customers/load'),
          dispatch('carriers/load'),
        ])
        .then(() => {
          resolve()
        })
        .catch(e => {
          commit('setError', e)
          reject(e)
        })
        .finally(() => commit('setLoading', false))
      })
    },
  },
  mutations: {
    setError (state, payload) {
      state.error = payload
    },
    setLoading (state, payload) {
      state.loading = payload
    },
  },
  modules,
}