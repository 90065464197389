import Vue from 'vue'

export default {
  namespaced: true,
  state: () => ({
    error: null,
    loading: false,

    data: [],
  }),
  getters: {
    error (state) {
      return state.error
    },
    loading (state) {
      return state.loading
    },

    data (state) {
      return state.data || []
    },

    formatter: (state, getters, rootState, rootGetters) => (boxLabel, index) => ({
      index,
      ...boxLabel,
      snapshots: boxLabel.snapshots ? (JSON.parse(boxLabel.snapshots) || {}) : {},
      merge_number_snapshots: boxLabel.merge_number_snapshots ? (JSON.parse(boxLabel.merge_number_snapshots) || {}) : {},
      yarnLabels: (boxLabel.yarnLabels || []).map((yarnLabel, index) => ({
        ...rootGetters['yarn_labels/formatter'](yarnLabel, index),
      })),
    }),
  },
  actions: {
    load ({commit, rootGetters}, options = {}) {
      const { before, ...payload } = {
        size: 50,
        page: 1,
        search: '',
        line_number: rootGetters['line'],
        ...options,
      }
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        commit('setError', null)
        Vue.http.post('box_labels/load', payload, before)
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          resolve(data)
        })
        .catch(e => {
          commit('setError', e)
          resolve({
            list: [],
            total: 0,
          })
        })
        .finally(() => commit('setLoading', false))
      })
    },
    submit ({commit, getters}, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        commit('setError', null)
        Vue.http.post('box_labels/submit', payload)
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          const boxLabel = getters.formatter(data)
          commit('updateData', boxLabel)
          resolve(boxLabel)
        })
        .catch(e => { commit('setError', e), reject(e) })
        .finally(() => commit('setLoading', false))
      })
    },
    get_by_id ({getters}, payload) {
      return new Promise((resolve, reject) => {
        Vue.http.post('box_labels/get_by_id', payload)
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          resolve(data ? getters.formatter(data) : null)
        })
        .catch(e => reject(e))
      })
    },
    get_by_code ({getters}, payload) {
      return new Promise((resolve, reject) => {
        Vue.http.post('box_labels/get_by_code', payload)
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          resolve(data ? getters.formatter(data) : null)
        })
        .catch(e => reject(e))
      })
    },
    delete ({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        commit('setError', null)
        Vue.http.post('box_labels/delete', payload)
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          commit('deleteData', data)
          resolve(data)
        })
        .catch(e => { commit('setError', e), reject(e) })
        .finally(() => commit('setLoading', false))
      })
    },
  },
  mutations: {
    setError (state, payload) {
      state.error = payload
    },
    setLoading (state, payload) {
      state.loading = payload
    },

    setData (state, payload) {
      state.data = payload
    },
    updateData (state, target) {
      const rowIndex = state.data.findIndex(row => row.id == target.id)
      if (rowIndex > -1) {
        state.data.splice(rowIndex, 1, target)
      } else {
        state.data.unshift(target)
      }
    },
    deleteData (state, target) {
      const rowIndex = state.data.findIndex(row => row.id == target.id)
      if (rowIndex > -1) {
        state.data.splice(rowIndex, 1)
      }
    },
  },
}