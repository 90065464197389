import Vue from "vue"
import VueRouter from "vue-router"
import routes from "./routes"
import store from '../store'
Vue.use(VueRouter)

// configure router
const router = new VueRouter({
  mode: 'history',
  routes, // short for routes: routes
  linkActiveClass: "active"
});

router.beforeEach((to, from, next) => {
  let isLoggedIn = store.getters['auth/isLoggedIn']
  let requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  let requiresAdmin = to.matched.some(record => record.meta.requiresAdmin)
  let requiresNonOperator = to.matched.some(record => record.meta.requiresNonOperator)
  let requiresLine = to.matched.some(record => record.meta.requiresLine)
  let is_chooseLinePage = to.matched.some(record => record.meta.chooseLinePage)

  if (!isLoggedIn && requiresAuth) {
    next('/login');
    return
  }

  if (isLoggedIn && (to.name === 'login')) {
    next('/')
    return
  }

  let authUser = store.getters['auth/user'];

  // debug
  console.log('authUser =');
  console.log(authUser);

  if (authUser && authUser.is_operator && requiresNonOperator) {
    store.dispatch('auth/logout')
    return
  }

  let line = store.getters['line']
  if (line && is_chooseLinePage) {
    next('/')
    return
  }
  if (requiresLine && !line && !is_chooseLinePage) {
    next('/choose-line')
    return
  }

  if (requiresAdmin && (!authUser || !authUser.is_admin)) {
    store.dispatch('notify', {
      message: 'You are not allowed to manage this feature.',
      type: 'danger',
    })
    next('/')
    return
  }

  sessionStorage.setItem('last_action', new Date())
  next();
})

export default router
