import auth from './auth'
import users from './users'
import yarn_labels from './yarn_labels'
import box_labels from './box_labels'
import pallet_labels from './pallet_labels'
import packing_slips from './packing_slips'
import settings from './settings'

export default {
  auth,
  users,
  yarn_labels,
  box_labels,
  pallet_labels,
  packing_slips,
  settings,
}
