import Vue from 'vue'

export default {
  namespaced: true,
  state: () => ({
    error: null,
    loading: false,

    data: [],
  }),
  getters: {
    error (state) {
      return state.error
    },
    loading (state) {
      return state.loading
    },

    data (state) {
      return state.data || []
    },

    formatter: (state, getters, rootState, rootGetters) => (packingSlip) => ({
      ...packingSlip,
      items: typeof packingSlip.items == 'string'  ? packingSlip.items.split(',').map(item => {
        const [pallet_number_from, pallet_number_to, merge_number_id] = item.split(':');
        return {
          pallet_number_from,
          pallet_number_to,
          merge_number_id: Number(merge_number_id),
        };
      }) : (packingSlip.items || []),
      boxLabels: (packingSlip.boxLabels || []).map((boxLabel, index) => rootGetters['box_labels/formatter'](boxLabel, index)),
    }),
  },
  actions: {
    load ({commit}, options) {
      const { before, ...payload } = {
        size: 50,
        page: 1,
        search: '',
        ...options,
      }
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        commit('setError', null)
        Vue.http.post('packing_slips/load', payload, before)
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          resolve(data)
        })
        .catch(e => {
          commit('setError', e)
          resolve({
            list: [],
            total: 0,
          })
        })
        .finally(() => commit('setLoading', false))
      })
    },
    submit ({commit, getters}, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        commit('setError', null)
        Vue.http.post('packing_slips/submit', payload)
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          const packingSlip = getters.formatter(data)
          commit('updateData', packingSlip)
          resolve(packingSlip)
        })
        .catch(e => { commit('setError', e), reject(e) })
        .finally(() => commit('setLoading', false))
      })
    },
    get_by_id ({getters}, payload) {
      return new Promise((resolve, reject) => {
        Vue.http.post('packing_slips/get_by_id', payload)
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          resolve(data ? getters.formatter(data) : null)
        })
        .catch(e => reject(e))
      })
    },
    delete ({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        commit('setError', null)
        Vue.http.post('packing_slips/delete', payload)
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          commit('deleteData', data)
          resolve(data)
        })
        .catch(e => { commit('setError', e), reject(e) })
        .finally(() => commit('setLoading', false))
      })
    },
  },
  mutations: {
    setError (state, payload) {
      state.error = payload
    },
    setLoading (state, payload) {
      state.loading = payload
    },

    setData (state, payload) {
      state.data = payload
    },
    updateData (state, target) {
      const rowIndex = state.data.findIndex(row => row.id == target.id)
      if (rowIndex > -1) {
        state.data.splice(rowIndex, 1, target)
      } else {
        state.data.push(target)
      }
    },
    deleteData (state, target) {
      const rowIndex = state.data.findIndex(row => row.id == target.id)
      if (rowIndex > -1) {
        state.data.splice(rowIndex, 1)
      }
    },
  },
}