<template>
  <div>
    <canvas ref="canvas" />
    <img ref="img" />
  </div>
</template>
<script>
import bwipjs from 'bwip-js'

export default {
  name: 'Barcode',
  props: [
    'type',
    'value',
    'width',
    'height',
  ],
  data() {
    return {
      svg: '',
    }
  },
  mounted() {
    const canvas = this.$refs['canvas']
    const options = {
      bcid: this.type, // Barcode type
      text: this.value, // Text to encode
      scale: 9,
    }
    if (this.height) {
      options.height = this.height / (1.5 * 96) * 25.4 // px -> milimeter (1in = 1.5(dpi) * 96(px per inch) px, 1in = 25.4mm;;; 1mm = 5.67px)
    }
    if (this.width) {
      // options.width = this.width / (1.5 * 96) * 25.4
    }
    bwipjs.toCanvas(canvas, options)
    this.$refs['img'].src = canvas.toDataURL('image/png')
  },
}
</script>